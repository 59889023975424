import {
  computed as vComputed,
  defineComponent,
  h,
  onMounted,
  onUnmounted,
  nextTick,
  reactive,
  readonly,
  toRefs,
  watch,
  onBeforeMount,
  onBeforeUpdate
} from 'vue'
import { ElMessage } from 'element-plus'
import { assign, bind, camelCase, get, omit, pick, isFunction, isPlainObject, trimStart, cloneDeep } from 'lodash-es'

import { Router, useRouter } from './router'

import { useStore } from './store'
import { ajax as _ajax } from './ajax'

import type {
  Component,
  ComponentPropsOptions,
  DefineComponent,
  ExtractPropTypes as VueExtractPropTypes,
  EmitsOptions as VueEmitsOptions,
  ObjectEmitsOptions,
  VNode,
  VNodeRef
} from 'vue'
import type { FormRules } from 'element-plus'
import type { AjaxActionsMap } from './ajax'

type ComputedGetter = (...args: any[]) => any
type ComputedSetter = (v: any) => void
interface WritableComputedOptions<T = any> {
  get?: ComputedGetter
  set?: ComputedSetter
}
type VueComputedOptions = Record<string, ComputedGetter | WritableComputedOptions>

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never

type EmitFn<Options = ObjectEmitsOptions, Event extends keyof Options = keyof Options> = Options extends Array<infer V>
  ? (event: V, ...args: any[]) => void
  : {} extends Options
  ? (event: string, ...args: any[]) => void
  : UnionToIntersection<
      {
        [key in Event]: Options[key] extends (...args: infer Args) => any ? (event: key, ...args: Args) => void : (event: key, ...args: any[]) => void
      }[Event]
    >

type WatchCallback<V = any, OV = any> = (value: V, oldValue: OV, onCleanup: (cleanupFn: () => void) => void) => any

type ObjectWatchOptionItem = {
  handler: WatchCallback
  immediate?: boolean
  deep?: boolean
}

type ExtractComputedTypes<CP> = {
  [K in keyof CP]: CP[K] extends () => infer R
    ? R
    : CP[K] extends { get: infer G }
    ? G extends (...args: any) => any
      ? ReturnType<G>
      : unknown
    : unknown
}

type ShallowAjaxConfig = {
  [K in keyof AjaxActionsMap]: {
    action: K
    params?: (payload: { payload: AjaxActionsMap[K]['server']; paths: any[] }, ...args: any[]) => void
    convert?: (data: AjaxActionsMap[K]['client'][]) => any
  }
}[keyof AjaxActionsMap]

export interface _TableConfig {
  align?: 'center'
  label?: string
  showOverflowTooltip?: boolean
  prop?: string
  visible?: boolean | (() => boolean)
  header?: {
    filter?: {
      type: 'select' | 'date' | 'text' | 'cascader'
      field: string | string[]
      props?: {
        ajax?: ShallowAjaxConfig
        [index: string]: any
      }
    }
    clickable?: boolean
    filterable?: boolean
    sortable?: boolean
  }
}

export interface TableConfig {
  [index: string]: _TableConfig
}

interface AjaxOptions {
  invokedByPagination?: boolean
  invokedByScroll?: boolean
  invokedByDynamicAddition?: boolean
  addition?: Record<string, any>
  paths?: Array<any>
  payload?: Record<string, any>
  config?: { ignores?: string[] }
}

type AjaxConfig = {
  [K in keyof AjaxActionsMap]: {
    action: K
    data?: 'array' | 'object' | 'string' | 'number'
    dirty?: true
    loading?: true
    pagination?: true
    init?: true
    validate?: true
    summary?: keyof AjaxActionsMap
    params?: (payload: { payload: AjaxActionsMap[K]['server']; data: AjaxActionsMap[K]['server'][]; paths: any[] }) => void
    convert?: (data: any, res: any) => any
  }
}[keyof AjaxActionsMap]

interface FactoryConfigOptions {
  [index: string]: any
  ajax?: Record<string, AjaxConfig>
  children?: Record<string, FactoryConfigOptions>
  config?: TableConfig
  rules?: FormRules
}

interface FactoryOptions<
  C,
  P,
  E,
  D,
  CP,
  M,
  CM,
  Config = C extends FactoryConfigOptions ? ExtractConfigTypes<C> : {},
  Props = Readonly<ExtractPropTypes<P>>,
  Data = D,
  Computed = ExtractComputedTypes<CP>,
  Methods = Readonly<M>,
  RawBindings = Config & Props & Data & Computed & Methods & CM
> {
  page?: boolean
  extends?: any
  config?: C & ThisType<RawBindings>
  components?: Record<string, Component>
  computed?: CP & ThisType<RawBindings>
  props?: P
  emits?: E & ThisType<void>
  data?: () => D
  methods?: M & ThisType<RawBindings>
  name?: string
  created?: (this: RawBindings) => void
  mounted?: (this: any) => void
  unmounted?: (this: RawBindings) => void
  // mounted?: (this: RawBindings) => void
  // unmounted?: (this: RawBindings) => void
  render?: (this: RawBindings, _h: typeof h) => VNode
  template?: string
  watch?: Record<string, ObjectWatchOptionItem & ThisType<RawBindings>>
}

type Index<C extends FactoryConfigOptions> = Omit<C, 'ajax' | 'children'>

type AjaxData<U> = Extract<U, { data: 'array' } | { data: 'object' } | { data: 'string' } | { data: 'number' }> extends {
  action: infer A
  data: infer D
  convert?: (...args: any) => infer C
}
  ? A extends keyof AjaxActionsMap
    ? {
        data: D extends 'array'
          ? Array<AjaxActionsMap[A]['client']>
          : D extends 'object'
          ? AjaxActionsMap[A]['client']
          : D extends 'string'
          ? string
          : D extends 'number'
          ? number
          : never
      } & {
        data: C
      }
    : {}
  : {}

type AjaxSummary<U> = Extract<U, { summary: string }> extends { summary: infer S }
  ? S extends keyof AjaxActionsMap
    ? {
        summary: {
          method: Promise<Array<AjaxActionsMap[S]['client']>>
          data: AjaxActionsMap[S]['client']
        }
      }
    : {}
  : {}

type AjaxFunction<U> = {
  [K in keyof U]: (
    options?: AjaxOptions
  ) => Promise<U[K] extends { action: infer A } ? (A extends keyof AjaxActionsMap ? AjaxActionsMap[A]['response'] : never) : never>
}

type AjaxLoading<U> = Extract<U, { loading: true }> extends never ? {} : { loading: boolean }

type AjaxDirty<U> = Extract<U, { dirty: true }> extends never ? {} : { dirty: boolean }

type AjaxPaging = { itemCount: number; pageCount: number; pageIndex: number; pageSize: number }

type AjaxPagination<U> = Extract<U, { pagination: true }> extends never ? {} : { paging: AjaxPaging }

type AjaxDataInit<U> = Extract<U, { init: true }> extends never
  ? Extract<U, { validate: true }> extends never
    ? {}
    : { init: () => void }
  : { init: () => void }

type Ajax<C extends FactoryConfigOptions> = C['ajax'] extends Record<string, infer U>
  ? AjaxFunction<C['ajax']> &
      AjaxData<U> &
      AjaxLoading<U> &
      AjaxDirty<U> &
      AjaxPagination<U> &
      AjaxDataInit<U> &
      AjaxSummary<U> & { $ajaxParams: { payload: Record<string, any>; paths: any[] } }
  : {}

type Computed<C extends FactoryConfigOptions> = C['computed'] extends Record<string, any> ? ExtractComputedTypes<C['computed']> : {}

type Children<C extends FactoryConfigOptions> = {
  [K in keyof C['children']]: C['children'][K] extends FactoryConfigOptions ? ExtractConfigTypes<C['children'][K]> : {}
}

type ExtractConfigTypes<C extends FactoryConfigOptions> = Index<C> & Ajax<C> & Computed<C> & Children<C>

type ExtractPropTypes<Props> = Omit<VueExtractPropTypes<Props>, keyof Array<any>>

interface ComponentCommonOptions<E> {
  ajax: typeof _ajax
  router: Router
  nextTick: (fn?: () => void, delay?: number) => Promise<void>
  dirtyCheck: (blockPath: string, message?: string | undefined) => Promise<boolean>
  emit: EmitFn<E>
  refs: Record<string, any>
  setRef: (name: string) => VNodeRef | undefined
  setRefs: (name: string) => VNodeRef | undefined
  store: ReturnType<typeof useStore>
}

export const factory = <
  ConfigOptions extends FactoryConfigOptions,
  PropsOptions extends ComponentPropsOptions,
  EmitsOptions extends VueEmitsOptions | Array<string>,
  DataOptions = {},
  ComputedOptions extends VueComputedOptions = {},
  MethodsOptions extends Record<string, any> = {},
  CommonOptions = ComponentCommonOptions<EmitsOptions>,
  Config = ExtractConfigTypes<ConfigOptions>,
  Props = Readonly<ExtractPropTypes<PropsOptions>>,
  Data = DataOptions,
  Computed = ExtractComputedTypes<ComputedOptions>,
  Methods = Readonly<MethodsOptions>,
  RawBindings = Config & Props & Data & Computed & Methods & CommonOptions
>(
  options: FactoryOptions<ConfigOptions, PropsOptions, EmitsOptions, DataOptions, ComputedOptions, MethodsOptions, CommonOptions> = {}
): DefineComponent<ExtractPropTypes<PropsOptions>, RawBindings, {}, {}, {}, {}, {}, EmitsOptions> => {
  const { components, config, emits, name, page = false, props, template } = options

  return defineComponent({
    extends: options.extends,
    name,
    components,
    emits,
    props: props as ComponentPropsOptions,
    template,

    setup(props, ctx) {
      const router = useRouter()
      const store = useStore()
      const state: any = reactive({})

      assign(state, {
        ...toRefs(props),
        ...ctx,
        store,
        ajax: _ajax,
        router,
        refs: {},
        dirtyMap: new Map()
      })

      assign(state, {
        setRef: (name: string) => (el: any) => (state.refs[name] = el),
        setRefs: (name: string) => (el: any) => {
          state.refs[name] ??= []
          state.refs[name].push(el)
        },
        nextTick,
        dirtyCheck: (blockPath: string, message?: string) => {
          const dirty = get(state, `${blockPath}.dirty`)
          if (dirty) ElMessage.warning(message ?? '当前数据未保存，请保存后重试')
          return new Promise((resolve, reject) => (dirty ? reject(true) : resolve(false)))
        }
      })

      assign(state, options.data?.call(state))

      assign(state, block(config ?? {}, '', state))

      assign(state, computed({ computed: options.computed }, '', state))

      Object.keys(options.methods ?? {}).forEach((name) => (state[name] = options.methods![name].bind(state)))

      Object.entries(options.watch ?? {}).forEach(([key, value]) => {
        watch(() => get(state, key), value.handler.bind(state), pick(value, ['deep', 'immediate']))
      })

      for (const [expression, config] of state.dirtyMap) {
        watch(() => get(state, expression), config.cb, config.options)
      }

      options?.created && onBeforeMount(options.created?.bind(state) as any)
      options?.mounted && onMounted(options.mounted?.bind(state) as any)
      options?.unmounted && onUnmounted(options.unmounted?.bind(state) as any)

      onBeforeUpdate(() => {
        state.refs = {}
      })

      page && router.setState(state)

      return options.render ? () => options.render?.call(state, h) : state
    }
  }) as any
}

const ajax = <O extends FactoryConfigOptions>(config: O, expression: string, state: any) => {
  const origin: any = {}
  if (config.ajax) {
    Object.entries(config.ajax).forEach(([key, value]) => {
      const { action, data: dataType, params, loading, pagination, summary, init, validate, convert, dirty } = value

      switch (dataType) {
        case 'array':
          origin.data = []
          break
        case 'object':
          origin.data = {}
          break
      }

      if (pagination) origin.paging = { pageIndex: 1, pageCount: 0, itemCount: 0, pageSize: 20 }
      if (loading) origin.loading = false
      if (dirty) {
        origin.dirty = false
        state.dirtyMap.set(`${trimStart(expression, '.')}.data`, {
          cb: () => {
            const parent = get(state, trimStart(expression, '.'))
            parent.dirty = true
          },
          options: { deep: true }
        })
      }

      if (summary) {
        origin.summary = { data: {} }
        origin.summary.method = bind(async function (this: any) {
          const parent = get(state, trimStart(expression, '.'))

          const ajaxParams = parent.$ajaxParams

          try {
            const res: any = await _ajax(parent.$lastAjaxConfig.summary ?? summary, ajaxParams)
            parent.summary.data = res.data[0]
            return Promise.resolve(res)
          } catch (err) {
            return Promise.resolve(err)
          }
        }, state)
      }

      if (init || validate) {
        origin.$initData ??= structuredClone(config.data) || {}
        origin.init ??= () => {
          const parent = get(state, trimStart(expression, '.'))
          parent.data = structuredClone(origin.$initData)
          nextTick(() => {
            state.refs[camelCase(expression)]?.clearValidate()
          })
        }
      }

      const method = async function (this: any, options: AjaxOptions = {}) {
        if (validate && state.refs[camelCase(expression)]) {
          try {
            await state.refs[camelCase(expression)].validate?.()
          } catch (err) {
            return Promise.reject(err)
          }
        }

        const parent = get(state, trimStart(expression, '.'))
        const ajaxParams: any = { payload: {}, paths: [] }
        params?.call(this, ajaxParams)
        ajaxParams.payload = cloneDeep(ajaxParams.payload)

        Object.assign(ajaxParams.payload, options.payload, options.addition)
        if (options.paths) ajaxParams.paths = [...ajaxParams.paths, ...options.paths]

        if (options.invokedByDynamicAddition) {
          parent.$addition ??= {}
          parent.$addition = options.addition
        }

        Object.assign(ajaxParams.payload, parent.$addition)

        parent.$ajaxParams = ajaxParams

        if (loading) parent.loading = true
        try {
          const res: any = await _ajax(action, ajaxParams, options.config)

          switch (dataType) {
            case 'array':
              res.data = convert?.call(state, res.data, res) ?? res.data
              if (pagination && options.invokedByScroll) {
                parent.data = [...parent.data, ...res.data]
              } else {
                parent.data = res.data
              }
              break
            case 'object':
              parent.data = convert?.call(state, res.data[0], res) ?? res.data[0] ?? {}
              break
            case 'string':
            case 'number':
              parent.data = convert?.call(state, res.data[0], res) ?? res.data[0]
              break
          }
          if (pagination && res.meta && res.meta.paging) parent.paging = res.meta.paging

          parent.$lastAjaxConfig = value
          if (parent.$lastAjaxConfig.summary) parent.summary.method()

          return Promise.resolve(res)
        } catch (err) {
          return Promise.reject(err)
        } finally {
          if (loading) parent.loading = false
          if (dirty) {
            setTimeout(() => (parent.dirty = false))
          }
        }
      }

      origin[key] = method.bind(state)
    })
  }
  return origin
}

const computed = <C extends FactoryConfigOptions>(config: C, expression: string, state: any) => {
  const origin: any = {}
  if (config.computed) {
    Object.entries(config.computed).forEach(([key, value]) => {
      if (isFunction(value)) {
        origin[key] = vComputed(value.bind(state))
      } else if (isPlainObject(value)) {
        origin[key] = vComputed({
          get: (value as any).get?.bind(state),
          set: (value as any).set?.bind(state)
        })
      }
    })
  }
  return origin
}

const children = <O extends FactoryConfigOptions>(config: O, expression: string, state: any) => {
  const origin: any = {}
  if (config.children) {
    Object.entries(config.children).forEach(([key, value]) => (origin[key] = block(value, `${expression}.${key}`, state)))
  }
  return origin
}

const index = <O extends FactoryConfigOptions>(config: O, expression: string, state: any) => {
  const origin: any = {}
  Object.entries(omit(config, ['ajax', 'computed', 'children'])).forEach(([key, value]) => {
    if (key === 'rules') {
      origin[key] = readonly(value)
      return
    }
    if (key === 'config') {
      origin[key] = readonly(value)
      return
    }
    origin[key] = isFunction(value) ? value.bind(state) : isPlainObject(value) ? block(value, `${expression}.${key}`, state) : value
  })
  return origin
}

const block = <O extends FactoryConfigOptions>(config: O, expression: string, state: any) => {
  const origin: any = {}
  assign(
    origin,
    ajax(config, expression, state),
    computed(config, expression, state),
    index(config, expression, state),
    children(config, expression, state)
  )
  return origin
}
