import { createRouter as _createRouter, createWebHistory, type Router as _Router } from 'vue-router'

import routes from 'routes'

import type { Plugin } from 'vue'

interface PushOptions {
  from: 'menu' | 'search' | 'router'
  data?: any
}

interface IBaseRouter {
  path: string
  onComplete?: (...args: any[]) => void
  onAbort?: (...args: any[]) => void
  options?: PushOptions
}

export class Router {
  state?: any
  pending: IBaseRouter[] = []

  constructor(private readonly _router: _Router) {
    this._router = _router
  }

  push(path: string, onComplete?: (...args: any[]) => void, onAbort?: (...args: any[]) => void, options?: PushOptions) {
    this.pending.push({ path, onComplete, onAbort, options })
    setTimeout(() => {
      this.pushHandler()
    })
  }

  async pushHandler() {
    const { path, onAbort } = this.pending[0]
    try {
      await this._router.push(path)
      this.pushCompleteCallback()
    } catch (err) {
      onAbort?.()
    }
  }

  pushCompleteCallback() {
    const { onComplete, options = { from: 'router', data: null } } = this.pending[0] ?? {}
    onComplete?.(this.state)
    this.pending = []
  }

  setState(state: any) {
    this.state = state
  }
}

const _router = _createRouter({ history: createWebHistory(), routes })
const router = new Router(_router)

export const createRouter = (): Plugin => {
  return {
    install(app) {
      app.use(_router)
    }
  }
}

export const useRouter = () => router
