import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { ajax } from './ajax'

let downloadTimer: NodeJS.Timer | null

export const useStore = defineStore('enocloud', () => {
  const user = ref<EnocloudSecurityDefinitions['UserDto']>({
    accessRights: [],
    advisorTeamMembers: [],
    bonusBalance: 0,
    discountLine: 0,
    managedBranches: [],
    managedWarehouses: [],
    workingTeamMembers: [],
    favorites: [],
    roles: [],
    managedBranchNames: ''
  })

  const noteShow = ref(true)

  const changeNoteShow = () => {
    noteShow.value = !noteShow.value
  }

  const changeUser = async () => {
    user.value = (await ajax('GET /enocloud/security/user'))?.data[0]
  }

  const accessRightsHash = computed(() => {
    const accessRightsHash: Record<string, boolean> = {}
    user.value.accessRights.forEach((item) => {
      accessRightsHash[item as string] = true
    })
    return accessRightsHash
  })

  const attributes = ref<EnocloudCommonDefinitions['SystemAttributeDto'][]>([])

  const attribute = computed(() => {
    return attributes.value.reduce(
      (target, attr) => {
        if (attr.id?.code) {
          target[attr.id.code] = {
            value: attr.value ?? '',
            message: attr.id.message ?? '',
            description: attr.id.description ?? '',
            type: attr.id.type ?? ''
          }
        }
        return target
      },
      {} as Record<string, { value: string; message: string; description: string; type: string }>
    )
  })

  const userTenantId = computed(() => user.value.tenant?.id.toLocaleUpperCase() ?? '')

  const changeAttribute = async () => {
    attributes.value = (await ajax('GET /enocloud/common/branch/attribute')).data
  }

  const ossInfo = ref<EnocloudCommonDefinitions['OssInfoDto']>()

  const changeOss = async () => {
    ossInfo.value = (await ajax('POST /enocloud/common/oss/signature')).data[0]
  }

  const collapse = ref(false)

  const changeCollapse = () => (collapse.value = !collapse.value)

  const refresh = () => {}

  const download = ref<{
    visible: boolean
    data: EnocloudCommonDefinitions['ReportDto'][]
    loading: boolean
    paging?: {
      itemCount: number
      pageCount: number
      pageIndex: number
      pageSize: number
    }
  }>({
    visible: false,
    data: [],
    loading: false,
    paging: {
      itemCount: 0,
      pageCount: 0,
      pageIndex: 1,
      pageSize: 20
    }
  })

  const getDownloadData = async () => {
    download.value.loading = true
    try {
      const res = await ajax('GET /enocloud/common/report')
      download.value.data = res.data
      download.value.paging = res.meta.paging
    } finally {
      download.value.loading = false
    }
  }

  const initDownloadTimer = () => {
    if (downloadTimer) return
    downloadTimer = setInterval(getDownloadData, 5000)
  }

  const openDownload = () => {
    getDownloadData()
    download.value.visible = true
    initDownloadTimer()
  }

  const refreshDownload = () => {
    getDownloadData()
  }

  const closeDownload = () => {
    download.value.visible = false
    if (downloadTimer) {
      clearInterval(downloadTimer)
      downloadTimer = null
    }
  }

  return {
    user,
    noteShow,
    changeNoteShow,
    changeUser,
    accessRightsHash,
    attribute,
    changeAttribute,
    ossInfo,
    changeOss,
    collapse,
    changeCollapse,
    refresh,
    userTenantId,
    download,
    openDownload,
    refreshDownload,
    closeDownload
  }
})
