import qs from 'qs'
import Axios from 'axios'
import { h } from 'vue'
import { forEach } from 'lodash-es'
import { ElNotification, ElMessageBox, ElCheckbox } from 'element-plus'

import type { RawAxiosRequestConfig } from 'axios'

export const axios = Axios.create({
  headers: { ENOCH_TERMINAL: 'WEB[ERP]' },
  paramsSerializer: {
    serialize(params) {
      return qs.stringify(params, { arrayFormat: 'repeat', allowDots: true })
    }
  }
})

axios.interceptors.response.use(
  (res) => {
    if (res.data.warnings?.[0]?.shouldNotNotification) {
      ElNotification({ title: '警告', message: res.data.warnings?.[0]?.message })
    }

    if (res.data.errors?.[0]?.shouldNotNotification) {
      ElNotification({ title: '请求失败', message: res.data.errors?.[0]?.message })

      return Promise.reject(res)
    }

    return Promise.resolve(res.data)
  },
  async (err: any) => {
    if (err.response?.data.errors && err.response.data.errors[0]) {
      if (!err.response.data.errors[0].shouldNotNotification) {
        ElNotification({ title: '请求失败', message: err.response.data.errors[0].message })
      }
    }

    if (err.response.data.confirmations && err.response.data.confirmations[0]) {
      const config = err.config
      config.data = JSON.parse(config.data)
      config.data.confirmations = config.data.confirmations || []
      let confirmations = err.response.data.confirmations
      const doubleCheck = confirmations[0].doubleCheck
      let doubleCheckValue
      return new Promise(async (resolve, reject) => {
        try {
          await ElMessageBox({
            distinguishCancelAndClose: true,
            draggable: true,
            showCancelButton: true,
            showConfirmButton: true,
            title: '警告',
            type: 'warning',
            message: h('div', [
              ...confirmations.map((c: any) => h('p', c.message)),
              doubleCheck
                ? h(ElCheckbox, {
                    label: '我已阅读',
                    onChange: (value) => (doubleCheckValue = value)
                  })
                : null
            ])
          })
          confirmations.forEach((c: any) => (c.confirmedOption = c.options.find((item: any) => item.code === 'Y')))
          config.data.confirmations.push(...confirmations)
          resolve(axios(config))
        } catch (err) {
          reject('cancel')
        }
      })
    }

    return Promise.reject(err.response)
  }
)

interface AjaxActionMapValue<RESPONSE, Client, Server> {
  response: RESPONSE
  client: Client
  server: Server
}

type _AjaxActionsMap<A extends keyof Actions, RESPONSE = any, CLIENT = any, QUERY = any, BODY = any> = A extends `${infer M} /${string}`
  ? M extends 'GET'
    ? AjaxActionMapValue<RESPONSE, CLIENT, QUERY>
    : M extends 'POST'
    ? AjaxActionMapValue<RESPONSE, unknown, BODY>
    : M extends 'PUT'
    ? AjaxActionMapValue<RESPONSE, unknown, BODY>
    : never
  : never

export type AjaxActionsMap = {
  [K in keyof Actions]: _AjaxActionsMap<
    K,
    Actions[K] extends { responses: { 200: { schema: infer S } } } ? S : never,
    Actions[K] extends { responses: { 200: { schema: { data: infer D } } } } ? (D extends Array<infer I> ? I : never) : never,
    Actions[K] extends { parameters: { query: infer Q } } ? Q : never,
    Actions[K] extends { parameters: { body: { data: infer D } } } ? (D extends Array<infer I> ? I : never) : never
  >
}

export type AjaxConfig = {
  [K in keyof AjaxActionsMap]: {
    action: K
    params?: (payload: { payload: AjaxActionsMap[K]['server']; paths: any[] }, ...args: any[]) => void
  }
}[keyof AjaxActionsMap]

interface AjaxOptions<A extends keyof AjaxActionsMap> {
  paths?: Array<string | number | undefined>
  payload?: AjaxActionsMap[A]['server']
  data?: AjaxActionsMap[A]['server'][]
}

export const ajax = async <A extends keyof AjaxActionsMap>(
  action: A,
  options: AjaxOptions<A> = {},
  config?: any
): Promise<AjaxActionsMap[A]['response']> => {
  const { paths = [], payload, data } = options
  const [method, path] = action.split(' ')
  let index = 0

  const url = path
    .split('/')
    .map((str) => (str.startsWith(':') ? paths[index++] : str))
    .join('/')

  const requestConfig: RawAxiosRequestConfig = { url, method }
  switch (method.toLowerCase()) {
    case 'get':
      requestConfig.params = payload
      break
    case 'post':
    case 'put':
    case 'delete':
      requestConfig.data = { data: data ?? (Object.keys(payload ?? {}).length ? [payload] : []) }
      break
  }

  requestConfig.transformResponse = (data) => {
    data = JSON.parse(data)
    if (config?.ignores) {
      forEach(['warnings', 'errors'], (field) => {
        if (data[field]) {
          // @ts-ignore
          const item = data[field].find((item) => config.ignores.find((code) => code === item.code))
          item && (item.shouldNotNotification = true)
        }
      })
    }
    return data
  }
  try {
    const res = await axios(requestConfig)
    return Promise.resolve(res) as any
  } catch (err) {
    return Promise.reject(err)
  }
}
