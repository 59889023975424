const tofixed = (num: number, precision: number = 0) => {
  let [int, fraction] = num.toString().split('.')
  if (!fraction) {
    return num.toFixed(precision)
  } else if (fraction.length <= precision) {
    fraction = fraction.padEnd(precision, '0')
    return int + '.' + fraction
  } else {
    if (Number(fraction[precision]) >= 5) {
      fraction = fraction.slice(0, precision)
      num = Number(int + fraction)
      let sign = num < 0 ? '-' : ''
      num = Math.abs(num)
      num++
      num = Number(sign + num.toString().padStart(precision + 1, '0'))
      num = Number(num.toString().slice(0, -precision) + '.' + num.toString().slice(-precision))
      return num
    } else {
      return int + '.' + fraction.slice(0, precision)
    }
  }
}

export const calculator = {
  add(num1: number = 0, num2: number = 0, d?: number) {
    const s1 = num1.toString()
    const s2 = num2.toString()
    const s1Arr = s1.split('.')
    const s2Arr = s2.split('.')
    const d1 = s1Arr.length == 2 ? s1Arr[1] : ''
    const d2 = s2Arr.length == 2 ? s2Arr[1] : ''
    const maxLen = Math.max(d1.length, d2.length)
    const m = Math.pow(10, maxLen)
    const result = Number(tofixed((num1 * m + num2 * m) / m, maxLen))
    return typeof d === 'number' ? Number(tofixed(result, d)) : result
  },
  sub(num1: number = 0, num2: number = 0, d?: number) {
    return calculator.add(num1, -Number(num2), d)
  },
  mul(num1: number = 0, num2: number = 0, d?: number) {
    const s1 = num1.toString()
    const s2 = num2.toString()
    const m = (s1.split('.')[1] ? s1.split('.')[1].length : 0) + (s2.split('.')[1] ? s2.split('.')[1].length : 0)
    const resultVal = (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m)
    return typeof d === 'number' ? Number(tofixed(resultVal, d)) : Number(resultVal)
  },

  div(num1: number = 0, num2: number = 0, d?: number) {
    const s1 = num1.toString()
    const s2 = num2.toString()
    const m = (s2.split('.')[1] ? s2.split('.')[1].length : 0) - (s1.split('.')[1] ? s1.split('.')[1].length : 0)
    let resultVal = (Number(s1.replace('.', '')) / Number(s2.replace('.', ''))) * Math.pow(10, m)
    resultVal = resultVal || 0
    return typeof d === 'number' ? Number(tofixed(resultVal, d)) : Number(resultVal)
  }
}
