import dayjs from 'dayjs'
import { isBoolean } from 'lodash-es'
import accounting from 'accounting'
import nzh from 'nzh'

import { calculator } from './calculator'

export const formatDate = (value: string | number | Date | undefined, prettier: string | boolean = 'YYYY-MM-DD') => {
  if (!value) return ''
  if (isBoolean(prettier)) return dayjs(value).format('YYYY-MM-DD HH:mm')
  else return dayjs(value).format(prettier)
}

export const formatDatem = (value: string | number | Date | undefined, prettier: string | boolean = 'YYYY-MM') => {
  if (!value) return ''
  if (isBoolean(prettier)) return dayjs(value).format('YYYY-MM')
  else return dayjs(value).format(prettier)
}

export const formatDatey = (value: string | number | Date | undefined, prettier: string | boolean = 'YYYY') => {
  if (!value) return ''
  if (isBoolean(prettier)) return dayjs(value).format('YYYY')
  else return dayjs(value).format(prettier)
}


export const formatMoney = (value: string | number | undefined, precision: number = 2, symbol: string = '￥') => {
  return accounting.formatMoney(value ?? 0, symbol, precision)
}

export const formatNumber = (number: number | undefined, precision?: number, thousand?: string, decimal?: string) => {
  return accounting.formatNumber(number ?? 0, precision)
}

export const formatPercent = (number: number | undefined, precision: number = 2) => {
  return formatNumber(calculator.mul(number ?? 0, 100), precision) + '%'
}

export const toMoney = (value: string | number | undefined) => (value ? nzh.cn.toMoney(value, { outSymbol: false }) : '')
